<template>
  <nav>
    <div class="profile-corner mt-0">
      <DropDownSetting />
    </div>
    <div class="profile-corner">
      <DropDownNotification />
    </div>
    <!-- <div
      class="profile-corner mt-2 mr-4"
      v-if="computeSuperAdmin || permission"
    >
      <b-form-group label="" label-for="regie" class="vue-select-modal-champ">
        <multiselect
          v-model="currentType"
          :options="ComputedTypeProjectList"
          :close-on-select="true"
          :clear-on-select="true"
          label="name"
          track-by="name"
          :searchable="false"
          :preselect-first="false"
          :showLabels="false"
          @input="handleChangeCurrentType"
          class="select-vue-component-style select-vue-component-style-type"
        >
        </multiselect>
      </b-form-group>
    </div> -->
    <div class="profile-corner title-project-cdg mt-3 mr-2">BarTH</div>
    <div class="profile-corner"><exportListCdg /></div>

    <!-- <div class="profile-corner mt-2 mr-2">
      <b-button
        size="sm"
        class="button-export-style ml-2 pl-2 pr-2 export-globale"
        title="Télecharger xls"
        v-b-modal.extract-modal-cdg
        @click.prevent.stop="getAllSociete"
        v-if="checkPermission('EGXLSCDG')"
      >
        <font-awesome-icon icon="cloud-download-alt"/>
        <div
          class="loader-export-globale-cdg"
          v-if="getLoadingExportAllFactureCdg == true"
        >
          <Half-circle-spinner
            :animation-duration="1000"
            :size="50"
            :color="'#4d4bac'"
            class="loader"
          /></div
      ></b-button>
    </div> -->
    <div class="menu-corner">
      <b-sidebar id="sidebar-backdrop" backdrop shadow><Menu /> </b-sidebar>
      <button class="icon mr-2">
        <font-awesome-icon
          icon="bars"
          v-b-toggle.sidebar-backdrop
          class="bar-icon"
        />
      </button>

      <div class="logo pl-3 pr-3">
        <!-- checkPermission('SGCDG') ? '/' : -->
        <router-link :to="'/'">
          <img src="../../assets/CDG.png" />
        </router-link>
      </div>
      <div class="menuTop"><Menu /></div>
    </div>
    <!-- Extract BDD Modal -->
    <b-modal
      ref="extract-modal-cdg"
      id="extract-modal-cdg"
      no-close-on-backdrop
      :hide-footer="true"
      :title="'Générer un xls'"
      @hidden="hideModal('extract-modal-cdg')"
      modal-class="modal-extaction-bdd-frais"
    >
      <div>
        <div>
          <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="6"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="8"
            label="Date début  : "
            label-for="extract-modal-cdg"
          >
            <date-picker
              :format="'YYYY-MM-DD'"
              value-type="format"
              type="date"
              required
              :value="date_debut"
              @input="handleDateTimePicker($event, 'date_debut')"
              :disabled-date="disabledStartDate"
              class="date-picker-export-globale"
            ></date-picker>
          </b-form-group>
          <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="6"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="8"
            label="Date fin : "
            label-for="extract-modal-cdg"
          >
            <date-picker
              :format="'YYYY-MM-DD'"
              value-type="format"
              type="date"
              :value="date_fin"
              @input="handleDateTimePicker($event, 'date_fin')"
              :clear-button="true"
              :disabled-date="disabledEndDate"
              class="date-picker-export-globale"
            ></date-picker>
          </b-form-group>
          <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="6"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="8"
            label="Société : "
          >
            <multiselect
              v-model="societe"
              :options="getSettingFilialesTh"
              :multiple="true"
              label="name"
              track-by="id"
              placeholder="sélectionner une société"
              required
              :showLabels="false"
              :loading="getLoadingSettingFilialeTh"
              class="multiselect-vue-custom-style-theme"
            >
            </multiselect>
          </b-form-group>
        </div>
      </div>
      <div class="form-modal-custom-style mt-2">
        <div class="messageError">
          <div v-if="getErrorExportAllFactureCdg" class="error">
            <ul v-if="Array.isArray(getErrorExportAllFactureCdg)">
              <li
                v-for="(e, index) in getErrorExportAllFactureCdg"
                :key="index"
              >
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getErrorExportAllFactureCdg }}</span>
          </div>
        </div>
        <div class="actionModel">
          <b-button
            class="button-valide-style "
            @click="handleExportFactureCdg"
            :disabled="getLoadingExportAllFactureCdg"
          >
            <span>
              Générer
            </span>
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- END Extract BDD Modal -->
  </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
export default {
  name: 'Topbar',

  data() {
    return {
      currentType: null,
      // currentType:
      //   localStorage.getItem('Current-Type') &&
      //   localStorage.getItem('Current-Type') != undefined
      //     ? JSON.parse(localStorage.getItem('Current-Type'))
      //     : { name: 'Iso à 1 €', value: 'iso' },
      TypeProjectList: [
        // { name: '', value: 'Th' },
        { name: 'Factures libres', value: 'libres' },
        { name: 'Factures SCI', value: 'sci' }
      ],
      date_fin: null,
      date_debut: null,
      societe: []
    };
  },
  components: {
    DropDownNotification: () => import('./DropdownNotification.vue'),
    DropDownSetting: () => import('./DropdownSetting.vue'),
    exportListCdg: () => import('@/views/component/exportListCdg'),

    Menu: () => import('./menu.vue')
  },
  mounted() {
    this.currentType = this.getCurrentType;
  },

  methods: {
    ...mapActions([
      'ChangeCurrentTpe',
      'exportFactureCdg',
      'getSettingFilialeTh'
    ]),
    handleChangeCurrentType(e) {
      this.ChangeCurrentTpe(e);
    },
    handleDateTimePicker(value, name) {
      this[name] = value;
    },
    disabledStartDate(date) {
      return (
        this.date_fin && moment(date, 'YYYY-MM-DD') >= new Date(this.date_fin)
      );
    },
    disabledEndDate(date) {
      return (
        this.date_debut &&
        moment(date, 'YYYY-MM-DD').add(1, 'days') < new Date(this.date_debut)
      );
    },
    resetModal() {
      this.date_debut = null;
      this.date_fin = null;
      this.societe = [];
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    handleExportFactureCdg() {
      this.exportFactureCdg({
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        societe: this.societe.length
          ? this.societe.map(item => {
              return item.id;
            })
          : this.getSettingFilialesTh.map(item => {
              return item.id;
            })
      });
      this.hideModal('extract-modal-cdg');
    },
    getAllSociete() {
      this.getSettingFilialeTh();
    }
  },
  computed: {
    ...mapGetters([
      'getUserData',
      'getCurrentType',
      'getErrorExportAllFactureCdg',
      'getLoadingExportAllFactureCdg',
      'getSettingFilialesTh',
      'getLoadingSettingFilialeTh',
      'isSuperAdminGetter',
      'checkPermission'
    ]),
    computeSuperAdmin: function() {
      return this.getUserData && this.getUserData.role === 'admin';
    },
    permission: function() {
      return this.getUserData && this.getUserData.permissions;
    },
    ComputedTypeProjectList() {
      let list = this.TypeProjectList;
      if (!this.checkPermission('FSTH')) {
        list = list.filter(item => item.value != 'sci');
      }
      if (!this.checkPermission('FL')) {
        list = list.filter(item => item.value != 'libres');
      }
      return list;
    }
  },
  watch: {
    getCurrentType(value) {
      this.currentType = this.getCurrentType;
    }
  }
};
</script>

<style lang="scss" scoped>
nav {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 48px;
  z-index: 999;
  background: #fff;
  .menu-corner {
    display: flex;
    height: 48px;
    .icon {
      display: none;
    }

    .logo {
      display: flex;
      font-size: 13px;
      font-weight: 800;
      align-items: center;
      padding: 0 30px;
      padding-right: 8%;
      img {
        width: 71px;
      }
    }
  }

  .profile-corner {
    float: right;
    display: flex;
    margin-top: 4px;
    &.title-project-cdg {
      font-weight: 700;
      padding: 6px 8px;
      font-size: 13px;
      line-height: 7px;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-decoration: none;
      color: #5d5d5d;
      border-radius: 25px;
      font-family: 'Montserrat', sans-serif;
    }
  }
}
@media screen and (max-width: 1200px) {
  nav {
    .menu-corner {
      .icon {
        background-color: transparent;
        float: left;
        display: block;
        border: none;
        height: 37px;
        width: 37px;
        color: #4d4bac;
        border-radius: 5px;
        outline: 0;
        margin-top: 6px;
        &:hover {
          outline: 0;
          border-radius: 50%;
          background-color: rgba(219, 217, 217, 0.54);
        }
        .bar-icon {
          outline: 0;
        }
      }
      .menuTop {
        display: none;
        width: 0px;
      }
    }
  }
}
.export-globale {
  background-color: #4d4bac !important;
  box-shadow: none !important;
  margin-top: 2px;
  padding: 6.5px;
}
.loader-export-globale-cdg {
  position: absolute;
  height: 93%;
  top: 4px;
  // right: 0px;
  // left: 0px;
  // bottom: 17px;
  // width: 100%;
  // padding-left: 20%;
  /* background-color: #ffffff87; */
  z-index: 10;
  .loader {
    margin-left: -34%;
    width: 43px !important;
    height: 42px !important;
  }
}
.date-picker-export-globale {
  width: 100%;
}
</style>
